import React, { Component } from 'react'
import Waypoint from 'react-waypoint'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import './Landing.scss'
import fcLogo from '../img/fc_logo.svg'
import menuBoard from '../img/menuboard.svg'
import coverVideo from '../img/coffee.mp4'
import VideoCover from 'react-video-cover'
import CenterSection from './CenterSection'
import ListSignup from './ListSignup';

class App extends Component {
  constructor () {
    super(...arguments)
    this._handleEnter = this._handleEnter.bind(this)
    this._handleLeave = this._handleLeave.bind(this)
  }

  render () {
    return (
      <div className='application__container'>
        <nav>
          <div className='fc__logo'><img src={fcLogo}></img></div>
          <ul className='nav-menu'>
            <li><Link to="/signup">Signup</Link></li>
          </ul>
        </nav>

        <div className='video-container'>
          <div className='video-content cover-section'>
            <div className='cover-section__left' style={{ width: '50%' }}>
              <h1>
                What is Full cup?
              </h1>
              <p>Full cup is the one-stop solution for offering curbside pickup, order ahead, and scheduled ordering to your business. Our apps are custom tailored for a professional app store presence.</p>
              <ListSignup />
            </div>
            <div className='cover-section__right'>
              <img src={menuBoard} className='menuboard' style={{ height: '50vh' }}></img>
            </div>
          </div>
          <div className='video-screen'></div>
          <VideoCover videoOptions={{ src: coverVideo, autoPlay: true, loop: true }} />
        </div>

        <CenterSection />

        <section className='cover-section cover-section--bottom'>
          <div>
            <h1>Affordable, Honest Pricing</h1>
            <table className='ui celled inverted structured table'>
              <thead>
                <tr>
                  <th rowSpan='2'>
                    Cost Name
                  </th>
                  <th colSpan='4'>
                    Location / Item count
                  </th>
                </tr>
                <tr>
                  <th>1 Location / 1-15 Items</th>
                  <th>
                    2 Locations / 16-29 Items
                  </th>
                  <th>
                    3 Locations / 30-100 Items
                  </th>
                  <th>
                    4+ Locations / 101+ Items
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>App Setup</td>
                  <td>$199</td>
                  <td>$299</td>
                  <td>$399</td>
                  <td>Call</td>
                </tr>
                <tr>
                  <td>Monthly</td>
                  <td>$50/month</td>
                  <td>$60/month</td>
                  <td>$100/month</td>
                  <td>Call</td>
                </tr>
                <tr>
                  <td>Curbside Addon</td>
                  <td>$20/month</td>
                  <td>$30/month</td>
                  <td>$50/month</td>
                  <td>Call</td>
                </tr>
                <tr>
                  <td>Rewards Addon</td>
                  <td>$10/month</td>
                  <td>$20/month</td>
                  <td>$40/month</td>
                  <td>Call</td>
                </tr>
                <tr>
                  <td>
                    Transaction Fee<br />
                    <small>Discounts Monthly Fee</small>
                  </td>
                  <td className='center aligned' colSpan="4">1.25% of full cup transactions</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <h1>What are you waiting for?</h1>
            <p>Drive on the go and curbside sales with a Full cup implementation. Get in touch to discuss your needs.</p>
          </div>

          <ListSignup className='list-signup--bottom' />
        </section>

        <footer>&copy; 2020 2X Creative, LLC. Fullcup / full cup / fullcupapp.com and other fullcup branding. All rights reserved.</footer>
        {/* <Navigation location={this.props.location} /> */}
      </div>
    )
  }

  _handleEnter (context) {
    return () => {
      this.setState({
        [`animateIn${context}`]: true
      })
    }
  }

  _handleLeave (context) {
    return () => {
      this.setState({
        [`animateIn${context}`]: false
      })
    }
  }
}

export default App
