import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import Landing from './components/Landing'
import Signup from './components/Signup'
import AppFinder from './components/AppFinder'
import NotFound from './components/NotFound'

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path='/' component={Landing} />
      <Route exact path='/signup' component={Signup} />
      <Route path='/app/:id' component={AppFinder} />
      <Route path='*' component={NotFound} />
    </Switch>
  </Router>
)

export default Routes
