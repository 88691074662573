import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './Signup.scss'
import fcLogo from '../img/fc_logo.svg'

class App extends Component {
  constructor () {
    super(...arguments)
    this._handleEnter = this._handleEnter.bind(this)
    this._handleLeave = this._handleLeave.bind(this)
  }

  componentDidMount () {
    var pricingTableComponentOptions = { id: 'zf-widget-root-id', product_id: '2-3d1aa8412b42a7d62f3de16d119c2d55928602716b5ca6525d8cc7aaf03de72fde821087e5af3b3ddcd6c241ff4b303685ced47588fa668d636280e0f950de09', template: 'elegant', most_popular_plan: 't1', is_group_by_frequency: false, group_options: [], plans: [{ plan_code: 't1' }, { plan_code: 't2' }, { plan_code: 't3' },], theme: { color: '#7952b3', theme_color_light: '' }, button_text: 'Sign up', product_url: 'https://subscriptions.zoho.com', price_caption: '+ One time setup %setupfee%', language_code: 'en', open_inSameTab: false };
    ZFWidget.init('zf-pricing-table', pricingTableComponentOptions);
  }

  render () {
    return (
      <div className='application__container'>
        <nav>
          <div className='fc__logo'><img src={fcLogo}></img></div>
          <ul className='nav-menu'>
            {/* <li><a href="#">How It Works</a></li>
            <li><a href="#">Pricing</a></li>
            <li><a href="#">Get Started</a></li> */}
          </ul>
        </nav>

        <div id="zf-widget-root-id"></div>

        <footer>&copy; 2020 2X Creative, LLC. Fullcup / full cup / fullcupapp.com and other fullcup branding. All rights reserved.</footer>
        {/* <Navigation location={this.props.location} /> */}
      </div>
    )
  }

  _handleEnter (context) {
    return () => {
      this.setState({
        [`animateIn${context}`]: true
      })
    }
  }

  _handleLeave (context) {
    return () => {
      this.setState({
        [`animateIn${context}`]: false
      })
    }
  }
}

export default App
