import React, { Component } from 'react'
import './NotFound.css'

class NotFound extends Component {
  render () {
    return (
      <div>
        We couldn&quot;t find that page.
      </div>
    )
  }
}

export default NotFound
