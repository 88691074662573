import React from 'react'
import { useParams } from 'react-router-dom'

const appDirectory = {
  'moav': {
    apple: 'https://apps.apple.com/us/app/id1492604882',
    android: 'https://play.google.com/store/apps/details?id=com.twox.moavmobile'
  }
}

function getMobileOS () {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'apple'
  }

  return 'apple'
}

export default function AppFinder () {
  let { id } = useParams()

  const appForId = appDirectory[id]

  if (!appForId) {
    window.location = '/not-found'
  }

  const mobileOs = getMobileOS()
  window.location = appForId[mobileOs]

  return (<div>
    If you are not redirected, <a href={appForId[mobileOs]}>click here</a>.
  </div>)
}
