import React, { Component } from 'react'
import './CenterSection.scss'
import kettle from '../img/kettle.svg'
import v60 from '../img/v60.svg'
import phone from '../img/phone.svg'
import ClientLogoMoav from '../img/clients/moav.png'

class CenterSection extends Component {
  // constructor() {
  //   super(...arguments)
  //   this._handleEnter = this._handleEnter.bind(this)
  //   this._handleLeave = this._handleLeave.bind(this)
  // }

  render () {
    return (
      <section className='cover-section cover-section--overlay-video cover-section--center'>
        <div className='cs-image-group'>
          <img src={kettle} className='cs-kettle' alt='Hand drawn image of a hot water kettle pouring into a drip filter' />
          <img src={v60} className='cs-v60' alt='A coffee drip filter called a Hario V60' />
          <img src={phone} className='cs-phone' alt='A phone displaying the full cup logo' />
        </div>

        <div className='cs-word-group'>
            <h1>Big Ideas Distilled For Small Business</h1>
          <p>Full cup allows for easy use of powerful features through use of your every day square terminal. Adjust items, take orders, receive curbside directions all from the devices you work on today.</p>

          <h1>Powerful Features</h1>
          <p>
              <ul>
              <li>Curbside pickup</li>
              <li>Rewards</li>
              <li>Scheduled orders</li>
              <li>Push notifications</li>
              <li>Tipping</li>
              <li>Sign in with Apple, Facebook or Email</li>
              <li>+ More</li>
            </ul>
            </p>
          </div>

        <div className='cs-word-group cs-word-group--clients'>
              <h1>Our Clients</h1>
          <ul className='client-image-group'>
            <li><a href='https://fullcupapp.com/app/moav'><img src={ClientLogoMoav} alt='MoAV Coffee logo' /></a></li>
          </ul>
        </div>
      </section>
    )
  }
}

export default CenterSection
