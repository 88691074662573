import React, { Component } from 'react'
import './ListSignup.scss'

class ListSignup extends Component {
  render () {
    return (
      <div {...this.props}>
        <div id='mc_embed_signup'>
          <form action='https://fullcupapp.us2.list-manage.com/subscribe/post?u=9b1344f82f18cac4f3f01a57d&amp;id=adc4f4f63c' method='post' id='mc-embedded-subscribe-form' name='mc-embedded-subscribe-form' className='validate' target='_blank' noValidate>
            <div id='mc_embed_signup_scroll'>
              <input type='email' name='EMAIL' className='email' id='mce-EMAIL' placeholder='Email Address' required />
              <div style={{
                position: 'absolute',
                left: -5000,
                ariaHidden: true
              }}>
                <input type='text' name='b_9b1344f82f18cac4f3f01a57d_adc4f4f63c' tabIndex='-1' value='' /></div>
              <div className='clear'><input type='submit' value='Sign up for a demo' name='subscribe' id='mc-embedded-subscribe' className='button' /></div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default ListSignup
